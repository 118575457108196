<template>
  <v-app style="min-width: 300px">
    <!-- navbar -->
    <div style="position: fixed; top: 0px; backgroundColor: white; height: 64px; width: 100vw; min-width: 300px; z-index: 9999; border-bottom: 0px solid gainsboro">
      <div class="d-flex align-center justify-space-between" style="height: 100%; width: 100vw; min-width: 300px">
        <!-- 로고 -->
        <div class="navbar-logo d-flex align-center">
          <img @click="goHome()" src="@/assets/logo.png" style="cursor: pointer; height: 24px">
        </div>
        <!-- 문의하기 버튼 - 웹 -->
        <div class="d-none d-sm-flex align-center justify-center" @click="openGoogleForm()"
          style="height: 40px; border: 1px solid #0D0E17; border-radius: 8px; background-color: #ffffff; padding: 0px 20px; margin-right: 84px; cursor: pointer"
        >
          <div style="font-size: 16px; font-weight: 600; line-height: 22px; color: #0D0E17">
            문의하기
          </div>
        </div>
        <!-- 문의하기 버튼 - 모바일 -->
        <div class="d-sm-none d-flex align-center justify-center" @click="openGoogleForm()"
          style="height: 40px; border: 1px solid #0D0E17; border-radius: 8px; background-color: #ffffff; padding: 0px 20px; margin-right: 10px; cursor: pointer"
        >
          <div style="font-size: 16px; font-weight: 600; line-height: 22px; color: #0D0E17">
            문의하기
          </div>
        </div>
      </div>
    </div>

    <v-main style="padding-top: 64px">
      <router-view/>
    </v-main>

    <!-- footer -->
    <div class="d-flex flex-column align-center justify-center" style="backgroundColor: #191f28; color: white">
      <!-- 웹 -->
      <div class="d-none d-sm-flex flex-column align-center justify-center py-12 px-6 text-center" style="width: 100%">
        <div class="mb-6" style="font-weight: 600">COGCOM Inc.</div>
        <div class="mb-4">(주)코그콤 | 사업자등록번호 : 378-86-01944 | 대표 : 정치훈</div>
        <div class="mb-4">서울특별시 광진구 광나루로 478 | 02-2088-2096 | contact@cogcom.kr</div>
      </div>
      <!-- 모바일 -->
      <div class="d-sm-none d-flex flex-column align-center justify-center py-6 px-6 text-center" style="width: 100%">
        <div class="mb-4" style="font-weight: 600">Cogcom Inc.</div>
        <div class="mb-1">(주)코그콤 | 대표 : 정치훈</div>
        <div class="mb-3">사업자등록번호 : 378-86-01944</div>
        <div class="mb-1">서울특별시 광진구 광나루로 478</div>
        <div class="mb-0">02-2088-2096 | contact@cogcom.kr</div>
      </div>
    </div>

    <!-- fab -->
    <!-- <div class="fab-area d-flex flex-column align-center">
      <v-btn fab x-small color="#2196F3" dark outlined class="mb-3" style="background-color: white" @click="goTop()">
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
      <v-btn small color="#2196F3" dark class="mb-3" @click="goContact()">
        문의하기
      </v-btn>
      <v-btn fab x-small color="#2196F3" dark outlined style="background-color: white" @click="goBottom()">
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
    </div> -->
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      navDrawer: false,
    }
  },
  methods: {
    openGoogleForm() {
      console.log('openGoogleForm')
      window.open('https://docs.google.com/forms/d/e/1FAIpQLSfzSDiA4w-MdVhY_huzxVm8E_Y4yJVSWqmHvSxcoOPZtQSTRg/viewform')
    },


    goHome() {
      this.$router.push('/').catch(() => {})
      scrollTo(0, 0)
      this.navDrawer = false
    },
    goSolution() {
      this.$router.push('/solution').catch(() => {})
      scrollTo(0, 0)
      this.navDrawer = false
    },
    goRecolumn() {
      window.open('https://www.recolumn.com/')
      this.navDrawer = false
    },
    goInventier() {
      window.open('https://inventier.cogcom.kr/')
      this.navDrawer = false
    },
    goAiVoucher() {
      this.$router.push('/ai-voucher').catch(() => {})
      scrollTo(0, 0)
      this.navDrawer = false
    },
    goContact() {
      this.$router.push('/contact').catch(() => {})
      scrollTo(0, 0)
      this.navDrawer = false
    },
    goTechnology() {
      this.$router.push('/technology').catch(() => {})
      scrollTo(0, 0)
      this.navDrawer = false
    },
    goService() {
      this.$router.push('/service').catch(() => {})
      scrollTo(0, 0)
      this.navDrawer = false
    },
    goCareer() {
      window.open('https://www.rocketpunch.com/companies/cogcom')
      this.navDrawer = false
    },
    closeNavDrawer() {
      this.navDrawer = false
    },
    goTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    goBottom() {
      window.scroll({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth'
      })
    },
  }
}
</script>

<style>
div {
  word-break: keep-all;
}

@media (min-width: 601px) {
  .navbar-logo {
    padding-left: 10vh;
  }
  .fab-area {
    position: fixed;
    bottom: 24px;
    right: 24px;
  }
}
@media (max-width: 600px) {
  .navbar-logo {
    padding-left: 10px
  }
  .fab-area {
    position: fixed;
    bottom: 12px;
    right: 12px;
  }
}
</style>